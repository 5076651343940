.m75 {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: grid;
}

.m75Content {
    height: auto;
    word-wrap: break-word;
    max-height: 1200px;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    color: var(--color-neutral-black);
    column-gap: var(--spacing-2);
    &.dark {
        color: var(--color-neutral-white);
    }

    @media --sm {
        display: grid;
        grid-auto-flow: column;
    }
}

.m75Content.mediaAlignRight {
    grid-template-areas: 'content media';
    grid-template-columns: 1fr 1fr;
}

.m75Content.mediaAlignLeft {
    grid-template-areas: 'media content';
    grid-template-columns: 1fr 1fr;
}

.m75Content [class*='alignContent'] {
    padding: var(--spacing-4);
    @media --md {
        padding: var(--spacing-8);
    }
}

.gridAreaMedia {
    grid-area: media;
    padding: var(--spacing-4);
    @media --md {
        padding: var(--spacing-8);
    }
}

.linkOverride {
    padding-left: 0;
}
